import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import titleMixin from "./mixins/titleMixin";
// import * as Sentry from "@sentry/browser";
// import * as Integrations from "@sentry/integrations";
import "./plugins/vuetify-mask.js";
import device from "vue-device-detector-js";
//import VueApexCharts from 'vue-apexcharts';

import axios from "axios";
import VueAxios from "vue-axios";
import { VueDraggable } from 'vue-draggable-plus';

import './assets/styles.css'; // Importa tu archivo CSS global

// Sentry.init({
//   dsn: "https://910936d55696486a815d25b5296425f4@sentry.idooproject.com/7",
//   integrations: [new Integrations.Vue({ Vue, attachProps: true })],
// });

Vue.use(VueAxios, axios);
Vue.component("vue-draggable", VueDraggable);
Vue.mixin(titleMixin);
Vue.use(device);
// Vue.use(VueApexCharts);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  // mask,
  render: (h) => h(App),
}).$mount("#app");

if (localStorage.getItem("store")) {
    store.replaceState(Object.assign({}, store.state, JSON.parse(localStorage.getItem("store"))));
}

window.addEventListener("beforeunload",()=>{
    localStorage.setItem("store",JSON.stringify(store.state));
});
