import '@mdi/font/css/materialdesignicons.css'
// import 'roboto-fontface/css/roboto/roboto-fontface.css'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
// import 'roboto-fontface/css/roboto/roboto-fontface.css';
import "@fontsource/montserrat"; // Defaults to weight 400
import "@fontsource/montserrat/400.css"; // Specify weight  
import "@fontsource/montserrat/400-italic.css"; // Specify weight and style

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import "@/assets/scss/vuetify/overrides.scss";

Vue.use(Vuetify);

const themeDark = {
    primary: "#398bf7", // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
    info: "#1e88e5",
    success: "#06d79c",
    accent: "#ef5350",
    default: "#563dea",
};

const themeLight = {
    primary: '#4d50ee',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
};

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: themeLight,
            dark: themeDark,
        },
        dark: false
    },
    icons: {
        iconfont: 'mdi',
    },
});
