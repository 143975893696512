import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baseUrl: process.env.VUE_APP_BHC_BASE_URL,
    Sidebar_drawer: null,
    searchPanel: false,
    textToSearch: null,
    resultSearch: null,
    isLogin: false,
    token: null,
    user: null,
    SidebarColor: "#1d2228", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "white",

    cart: JSON.parse(localStorage.getItem("cart")) || [],
  },
  mutations: {
    // cart

    ADD_TO_CART(state, product) {
      state.cart = state.cart || [];

      // Verifica si ya hay un elemento en el carrito con el mismo profile.id

      const existingItemIndex = state.cart.findIndex(
        (item) =>
          item.category === product.category &&
          item.profile.id === product.profile.id
      );

      if (existingItemIndex === -1) {
        // Si no hay un elemento existente, agrega el producto al carrito
        state.cart.push(product);
        localStorage.setItem("cart", JSON.stringify(state.cart));
      } else {
        console.warn("El producto ya está en el carrito");
      }
    },

    REMOVE_FROM_CART(state, index) {
      if (index >= 0 && index < state.cart.length) {
        state.cart.splice(index, 1);
        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
    },

    CLEAR_CART(state) {
      state.cart = [];
      localStorage.removeItem("cart");
    },

    /////////////////////////////////////////////////

    SetUser(state, payload) {
      state.user = payload;
    },
    SetIslogin(state, payload) {
      state.isLogin = payload;
    },
    SetToken(state, payload) {
      state.token = payload;
    },
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    resetStateLogin(state) {
      state.isLogin = false;
      state.token = null;
      state.user = null;
    },
    ShowSearchPanel(state) {
      state.searchPanel = true;
    },
    HideSearchPanel(state) {
      state.searchPanel = false;
    },
    ClearSearchText(state) {
      state.textToSearch = null;
    },
    ClearResultSearch(state) {
      state.resultSearch = [];
    },
    SetSearchText(state, payload) {
      state.textToSearch = payload;
    },
    SetResultSearch(state, payload) {
      state.resultSearch = payload;
    },
  },
  actions: {
    // cart

    addToCart({ commit }, product) {
      //   console.log(product);
      commit("ADD_TO_CART", product);
    },

    removeFromCart({ commit }, index) {
      commit("REMOVE_FROM_CART", index);
    },

    clearCart({ commit }) {
      commit("CLEAR_CART");
    },

    ////////////////////////////////////////////////////

    clearStateLogin({ commit }) {
      commit("resetStateLogin");
    },
    async SearchText(context) {
      let response = await axios.get(
        `${context.state.baseUrl}/search?value=${context.state.textToSearch}`
      );
      if (response.data.success) {
        console.log("Datos Todos 999 response.data:", response.data.result);
        var valor = response.data.result;
        var resultados = [];
        for (var i = 0; i < valor.length; i++) {
          if (valor[i].route == "DoctorProfile"){
            if (valor[i].data.coepris_qr){
              var fecha = valor[i].data.vencimiento_fecha;
              if (fecha){
                // Obtiene solo la parte de la fecha
                const fechaVencimiento = fecha.split("T")[0];
                // Formatea la fecha actual en el mismo formato
                const fechaActual = new Date().toISOString().split("T")[0];
                // Compara las fechas
                if (fechaVencimiento > fechaActual){
                  resultados.push(valor[i]);
                }
              }
            }
          }
          else {
            resultados.push(valor[i]);
          }
        }
        console.log("Datos Final 88888 resultados:", resultados);
        //context.commit("SetResultSearch", response.data.result);
        context.commit("SetResultSearch", resultados);
      } else {
        context.commit("ClearResultSearch");
      }
    },
  },
  getters: {
    getBaseURL: (state) => state.baseUrl,
    getUser: (state) => state.user,
    getToken: (state) => state.token,
    isLogin: (state) => state.isLogin,
    getResultSearch: (state) => state.resultSearch,
    getTextToSearch: (state) => state.textToSearch,

    // cart

    getCart: (state) => state.cart,

    ////////////////////////////////////
  },
});
